import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    betSize: null,
    isLocked: false,
    isRolling: false,
    displayedChipBalance: null,
    displayedAirDropBalance: null,
    isSoundEnabled: true,
    selectedDiceOutcome: 'low',
    consecutiveWins: 0
};

const diceSlice = createSlice({
    name: 'dice',
    initialState,
    reducers: {
        setConsecutiveWins(state, action) {
            const consecutiveWins = action.payload;
            state.consecutiveWins = consecutiveWins;
        },
        setBetSize(state, action) {
            const betSize = action.payload;
            state.betSize = betSize;
        },
        setLocked(state, action) {
            const isLocked = action.payload;
            state.isLocked = isLocked;
        },
        setRolling(state, action) {
            const isRolling = action.payload;
            state.isRolling = isRolling;
        },
        setDisplayedChipBalance(state, action) {
            const displayedChipBalance = action.payload;
            state.displayedChipBalance = displayedChipBalance;
        },
        setDisplayedAirDropBalance(state, action) {
            const displayedAirDropBalance = action.payload;
            state.displayedAirDropBalance = displayedAirDropBalance;
        },
        setSoundEnabled(state, action) {
            const isSoundEnabled = action.payload;
            state.isSoundEnabled = isSoundEnabled;
        },
        setSelectedDiceOutcome(state, action) {
            const selectedDiceOutcome = action.payload;
            state.selectedDiceOutcome = selectedDiceOutcome;
        }
    }
});

export const {
    setBetSize,
    setLocked,
    setRolling,
    setDisplayedAirDropBalance,
    setDisplayedChipBalance,
    setSoundEnabled,
    setSelectedDiceOutcome,
    setConsecutiveWins
} = diceSlice.actions;
export default diceSlice.reducer;

