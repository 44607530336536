import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import ReferralScreen from './screens/ReferralScreen/ReferralScreen';
import PrizeScreen from './screens/PrizeScreen';
import WalletScreen from './screens/WalletScreen/WalletScreen';
import EarnScreen from './screens/EarnScreen';
import SettingsScreen from './screens/SettingsScreen';
import Header from './components/Header';
import NavigationBar from './components/NavigationBar';
import SplashScreen from './screens/SplashScreen';
import './App.css';
import SlotsScreen from './screens/SlotsScreen';
import DiceScreen from './screens/DiceScreen';
import { useExpand } from '@vkruglikov/react-telegram-web-app';
import { Provider } from 'react-redux';
import store from './app/store';
import Modal from "react-modal";
import AirdropPopUp from './components/AirdropPopUp';
import RewardPopUp from './components/RewardPopUp';
import NotificationsScreen from './screens/NotificationsScreen';
import TermsOfUseScreen from './screens/TermsOfUseScreen';
import { useMemo } from 'react';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { useGetScreenIfNotAuthorized } from './utils/routes';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { appUrl } from './constants';

Modal.setAppElement("#root");

function App() {

  const [isExpanded, expand] = useExpand();
  if (!isExpanded) expand()
  return (
    <TonConnectUIProvider manifestUrl={`${appUrl}/tonconnect-manifest.json`} uiPreferences={{ theme: 'DARK' }}>
      <Provider store={store}>
        <div id="root">
          <Router>
            <AppRoutes />
          </Router>
        </div>
      </Provider>
    </TonConnectUIProvider >
  );
}

const AppRoutes = () => {
  const [, initData] = useInitData();
  const [shouldShowSplashScreen, setShowSplashScreen] = useState(initData !== "");
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);

  const handleSplashFinish = () => {
    setShowSplashScreen(false);
  };

  const handleTermsAccepted = () => {
    setAreTermsAccepted(true);
    localStorage.setItem('areTermsAccepted', 'true');
  }

  useEffect(() => {
    const termsAccepted = localStorage.getItem('areTermsAccepted');
    if (termsAccepted) {
      setAreTermsAccepted(true);
    }
  }, []);

  const accessToken = useMemo(() => localStorage.getItem("accessToken"),
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [shouldShowSplashScreen]);

  const screenIfNotAuthorized = useGetScreenIfNotAuthorized();

  if (shouldShowSplashScreen) {
    return <SplashScreen onFinish={handleSplashFinish} />;
  }

  if (accessToken === null) {
    return screenIfNotAuthorized;
  }

  if (!areTermsAccepted) {
    return <TermsOfUseScreen onTermsAccepted={handleTermsAccepted} hasTermsBeenAccepted={false} />;
  }


  return <>
    <Header />
    <div className="content-container">
      <AirdropPopUp />
      <RewardPopUp />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/terms-of-use" element={<TermsOfUseScreen hasTermsBeenAccepted={true} />} />
        <Route path="/prize" element={<PrizeScreen />} />
        <Route path="/referral" element={<ReferralScreen />} />
        <Route path="/notifications" element={<NotificationsScreen />} />
        <Route path="/wallet" element={<WalletScreen />} />
        <Route path="/earn" element={<EarnScreen />} />
        <Route path="/settings" element={<SettingsScreen />} />
        <Route path="/game/slots" element={<SlotsScreen />} />
        <Route path="/game/dice" element={<DiceScreen />} />
      </Routes>
    </div>
    <NavigationBar />
  </>;
}

export default App;
