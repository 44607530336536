import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import SlotMachineReel from "./SlotMachineReel/SlotMachineReel";
import slotsMachineBackground from "../assets/images/slot_machine/slot_machine_background.png";
import { useCountUp } from "react-countup";
import scoreCounterSoundSource from "../assets/sounds/coin_win_effect.wav";
import reelSpinSoundSource from "../assets/sounds/reel_spin.mp3";
import leverPullSoundSource from "../assets/sounds/lever_pull.mp3";
import airdropSoundSource from "../assets/sounds/airdrop_sound.wav";
import SoundButton from "./Slots/SoundButton";
import BalanceIndicator from "./Slots/BalanceIndicator";
import TextWithStroke from "./Slots/TextWithStroke";
import majorWinSoundSource from "../assets/sounds/major_win_sound.mp3";
import store from "../app/store";
import { Howl } from "howler";
import { dollarWorthInChips } from "../constants";
import MinorWinEffect from "./MinorWinEffect";

const SlotMachine = forwardRef(({minorWinEffectRef}, ref) => {
  const reel1Ref = useRef(null);
  const reel2Ref = useRef(null);
  const reel3Ref = useRef(null);
  const reel4Ref = useRef(null);
  const reel5Ref = useRef(null);
  const [isBigCounterVisible, setBigCounterVisible] = useState(false);
  const hideCounterTimeoutRef = useRef(null);

  const { isSoundEnabled, displayedAirDropBalance, displayedChipBalance } =
    useSelector((state) => state.slots);

  const { update: updateChipCounter } = useCountUp({
    ref: "chip-counter",
    start: displayedChipBalance,
    delay: 0,
    duration: 3,
    decimals: 2,
    prefix: "",
    useEasing: true,
  });

  const { reset: resetBigUsdCounter, update: updateBigUsdCounter } = useCountUp(
    {
      ref: "big-usd-counter",
      start: 0,
      end: 0,
      delay: 0,
      duration: 3,
      decimals: 2,
      prefix: "$",
      useEasing: true,
    }
  );

  const { reset: resetAirdropCounter, update: updateAirdropCounter } =
    useCountUp({
      ref: "airdrop-counter",
      start: displayedAirDropBalance,
      delay: 0,
      duration: 3,
      decimals: 2,
      prefix: "",
      useEasing: true,
    });

  const scoreCounterSound = useMemo(
    () =>
      new Howl({
        src: [scoreCounterSoundSource],
        volume: 1.0, // Adjust volume as needed
        preload: true,
        html5: true,
      }),
    []
  );

  const reelSpinSound = useMemo(
    () =>
      new Howl({
        src: [reelSpinSoundSource],
        volume: 1.0, // Adjust volume as needed
        preload: true,
        html5: true,
      }),
    []
  );

  const airDropSound = useMemo(
    () =>
      new Howl({
        src: [airdropSoundSource],
        volume: 1.0, // Adjust volume as needed
        preload: true,
        html5: true,
      }),
    []
  );

  const leverPullSound = useMemo(
    () =>
      new Howl({
        src: [leverPullSoundSource],
        volume: 0.5, // Specific volume for lever pull
        preload: true,
        html5: true,
      }),
    []
  );

  const majorWinSound = useMemo(
    () =>
      new Howl({
        src: [majorWinSoundSource],
        volume: 1.0, // Adjust volume as needed
        preload: true,
        html5: true,
      }),
    []
  );

  const sounds = useMemo(
    () => [
      scoreCounterSound,
      reelSpinSound,
      airDropSound,
      leverPullSound,
      majorWinSound,
    ],
    []
  );

  const playSound = (sound) => {
    sounds.forEach((s) => s.stop());
    sound.play();
  };

  useImperativeHandle(ref, () => ({
    spin: async ({
      reel1Position,
      reel2Position,
      reel3Position,
      reel4Position,
      reel5Position,
    }) => {
      if (hideCounterTimeoutRef.current) {
        clearTimeout(hideCounterTimeoutRef.current);
        hideCounterTimeoutRef.current = null;
      }

      playSound(leverPullSound);
      await new Promise((resolve) => setTimeout(resolve, 400));
      playSound(reelSpinSound);
      await Promise.all([
        reel1Ref.current.spin(reel1Position),
        reel2Ref.current.spin(reel2Position),
        reel3Ref.current.spin(reel3Position),
        reel4Ref.current.spin(reel4Position),
        reel5Ref.current.spin(reel5Position),
      ]);
    },

    showAirdropPoints: (data) => {
      resetAirdropCounter();
      playSound(airDropSound);
      const currentAirdropBalance =
        store.getState().slots.displayedAirDropBalance;
      updateAirdropCounter(data.airdropPointsReward + currentAirdropBalance);
      return new Promise((resolve) => setTimeout(resolve, 3000));
    },

    updateBigUsdCounter: async (result) => {
      const { winningAmountInChips } = result;
      resetBigUsdCounter();
      setBigCounterVisible(true);
      playSound(majorWinSound);
      const currentChipBalance = store.getState().slots.displayedChipBalance;
      updateChipCounter(currentChipBalance + winningAmountInChips);
      updateBigUsdCounter(
        (winningAmountInChips / dollarWorthInChips).toFixed(2)
      );
      await new Promise((resolve) => {
        return setTimeout(resolve, 7000);
      });
      setBigCounterVisible(false);
    },
    showPayLinesWithRewards: async (
      {
        reel1Position,
        reel2Position,
        reel3Position,
        reel4Position,
        reel5Position,
        payLines,
      },
      updateCounter = true
    ) => {
      const reelSetup = [
        reel1Position,
        reel2Position,
        reel3Position,
        reel4Position,
        reel5Position,
      ];

      const sortedPayLines = [...payLines].sort(
        (a, b) => a.rewardInChips - b.rewardInChips
      );
      let displayedWinnings = 0;
      for (const payLine of sortedPayLines) {
        const positions = payLine.relativePositions;
        [reel1Ref, reel2Ref, reel3Ref, reel4Ref, reel5Ref].forEach(
          (reel, index) => {
            if (positions[index] !== null) {
              reel.current?.highlightSymbol(
                reelSetup[index] + positions[index]
              );
            }
          }
        );
        playSound(scoreCounterSound);
        if (updateCounter) {
          displayedWinnings += payLine.rewardInChips;
          const currentChipBalance =
            store.getState().slots.displayedChipBalance;
          updateChipCounter(currentChipBalance + displayedWinnings);
        }

        await new Promise((resolve) => setTimeout(resolve, 2000));

        [reel1Ref, reel2Ref, reel3Ref, reel4Ref, reel5Ref].forEach((reel) => {
          reel.current?.highlightSymbol(null);
        });
      }
    },

    isSpinning: () =>
      reel1Ref.current?.isSpinning() ||
      reel2Ref.current?.isSpinning() ||
      reel3Ref.current?.isSpinning() ||
      reel4Ref.current?.isSpinning() ||
      reel5Ref.current?.isSpinning(),
  }));

  useEffect(() => {
    return () => {
      airDropSound.stop();
      scoreCounterSound.stop();
      leverPullSound.stop();
      majorWinSound.stop();
      reelSpinSound.stop();
    };
  }, [
    airDropSound,
    scoreCounterSound,
    leverPullSound,
    majorWinSound,
    reelSpinSound,
  ]);

  useEffect(() => {
    airDropSound.mute(!isSoundEnabled);
    scoreCounterSound.mute(!isSoundEnabled);
    leverPullSound.mute(!isSoundEnabled);
    majorWinSound.mute(!isSoundEnabled);
    reelSpinSound.mute(!isSoundEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSoundEnabled]);

  return (
    <div>
      <div className="flex flex-col items-center justify-start">
        <div className="flex flex-row items-center w-full justify-between mt-[1.5rem]">
          <div className="h-full mb-[-1.5rem] ml-[0.1rem]">
            <SoundButton />
          </div>
          <div className="flex flex-row items-center">
            <BalanceIndicator title={"Credit"} idToPass={"chip-counter"} />
            <BalanceIndicator
              title={"AirDrop CC"}
              idToPass={"airdrop-counter"}
            />
          </div>
        </div>
        <div className="relative">
          <div
            className="absolute text-5xl text-center"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 10, // Ensure it is on top of other content
              display: isBigCounterVisible ? "block" : "none",
              transition: "display 1.5s",
            }}
          >
            <TextWithStroke text={"You win"} />
            <TextWithStroke idToPass={"big-usd-counter"} />
          </div>
          <div
            className="w-[97vw] flex flex-row justify-between px-[0.68rem] bg-no-repeat bg-center bg-cover aspect-[1060/862] items-center overflow-hidden rounded-2xl pt-1.5"
            style={{
              backgroundImage: `url(${slotsMachineBackground})`,
              filter: isBigCounterVisible ? "blur(5px)" : "",
              transition: "filter 1.5s",
            }}
          >
            <MinorWinEffect ref={minorWinEffectRef} />
            <SlotMachineReel ref={reel1Ref} />
            <div className="pr-1">
              <SlotMachineReel ref={reel2Ref} />
            </div>
            <SlotMachineReel ref={reel3Ref} />
            <div className="pl-1">
              <SlotMachineReel ref={reel4Ref} />
            </div>
            <SlotMachineReel ref={reel5Ref} isLast />
          </div>
        </div>
      </div>
    </div>
  );
});

export default SlotMachine;
