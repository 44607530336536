import rollButtonImage from "../../assets/images/dice/roll_button.png";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { useNavigateToDeposits } from "../../utils/routes";

const RollButton = ({ onClick }) => {
  const { isLocked, isRolling, selectedDiceOutcome, betSize } = useSelector(
    (state) => state.dice
  );
  const navigateToDeposits = useNavigateToDeposits();

  const isDisabled = useMemo(() => {
    return (
      isRolling ||
      isLocked ||
      selectedDiceOutcome === null ||
      selectedDiceOutcome === undefined
    );
  }, [isRolling, isLocked, selectedDiceOutcome]);

  return (
    <>
      <button
        onClick={betSize >= 10 ? onClick : navigateToDeposits}
        className="aspect-[974/278] w-[55%] bg-cover bg-center bg-no-repeat posterable pb-2 text-2xl"
        style={{
          backgroundImage: `url(${rollButtonImage})`,
          filter: isDisabled ? "grayscale(100%)" : "none",
        }}
        disabled={isDisabled}
      >
        <div
          style={{
            mixBlendMode: "overlay",
          }}
        >
          {betSize >= 10 ? "Roll" : "Add funds"}
        </div>
      </button>
      {betSize < 10 && (
        <div
          className="absolute bottom-5 posterable opacity-50 text-xs"
          style={{}}
        >
          Minimum bet size is 10
        </div>
      )}
    </>
  );
};

export default RollButton;
