import React, { useRef, useEffect, useMemo, useCallback } from "react";
import { Howl } from "howler";
import { useRoll } from "../utils/dice";

// Temporary animations
import diceBackground from "../assets/images/dice/background.png";
import RollButton from "../components/Dice/RollButton";
import DiceSphere from "../components/Dice/DiceSphere";
import ParticularOutcomeSelectionField from "../components/Dice/ParticularOutcomeSelectionField";
import DoubleButton from "../components/Dice/DoubleButton";
import HalfOutcomeSelectionField from "../components/Dice/HalfOutcomeSelectionField";
import BetSizeInput from "../components/Dice/BetSizeInput";
import BalanceIndicator from "../components/Dice/BalanceIndicator";
import { useCountUp } from "react-countup";
import { useSelector } from "react-redux";
import {
  setDisplayedAirDropBalance,
  setDisplayedChipBalance,
  setLocked,
  setRolling,
} from "../features/diceSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useUserBalance } from "../utils/user";
import airdropCoinIcon from "../assets/images/dice/airdrop_coin_icon.png";
import chipIcon from "../assets/images/dice/chip_icon.png";
import store from "../app/store";
import scoreCounterSoundSource from "../assets/sounds/coin_win_effect.wav";
import airdropSoundSource from "../assets/sounds/airdrop_sound.wav";
import diceRollSoundSource from "../assets/sounds/dice_roll.mp3";
import SquareIconButton from "../components/Dice/SquareIconButton";
import leftArrowIcon from "../assets/images/slot_machine/left_arrow_icon.svg";
import "./DiceScreen.css";
import SoundButton from "../components/Dice/SoundButton";
import AirdropEffect from "../components/AirdropEffect";

const DiceScreen = () => {
  const diceRef = useRef();
  const airdropEffectRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { displayedAirDropBalance, displayedChipBalance, isSoundEnabled } =
    useSelector((state) => state.dice);

  const { chipBalance, airdropBalance } = useUserBalance();

  const { update: updateChipCounter } = useCountUp({
    ref: "chip-counter",
    start: displayedChipBalance,
    delay: 0,
    duration: 3,
    decimals: 2,
    prefix: "",
    useEasing: true,
  });

  const { update: updateAirdropCounter } = useCountUp({
    ref: "airdrop-counter",
    start: displayedAirDropBalance,
    delay: 0,
    duration: 3,
    decimals: 2,
    prefix: "",
    useEasing: true,
  });
  const scoreCounterSound = useMemo(
    () =>
      new Howl({
        src: [scoreCounterSoundSource],
        volume: 1.0, // Adjust volume as needed
        preload: true,
        html5: true,
      }),
    []
  );

  const airDropSound = useMemo(
    () =>
      new Howl({
        src: [airdropSoundSource],
        volume: 1.0, // Adjust volume as needed
        preload: true,
        html5: true,
      }),
    []
  );

  const diceRollSound = useMemo(
    () =>
      new Howl({
        src: [diceRollSoundSource],
        volume: 1.0, // Adjust volume as needed
        preload: true,
        html5: true,
      }),
    []
  );

  const sounds = useMemo(
    () => [diceRollSound, scoreCounterSound, airDropSound],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const playSound = useCallback(
    (sound) => {
      sounds.forEach((s) => s.stop());
      sound.play();
    },
    [sounds]
  );

  const showWinningsIfNeeded = useCallback(
    async ({ winningAmountInChips }) => {
      if (winningAmountInChips === 0) return;
      const currentChipBalance = store.getState().dice.displayedChipBalance;
      playSound(scoreCounterSound);
      diceRef.current?.highlightWinning();
      updateChipCounter(currentChipBalance + winningAmountInChips);

      await new Promise((resolve) => setTimeout(resolve, 3000));
    },
    [diceRef, scoreCounterSound, updateChipCounter, playSound]
  );

  const showAirdropWinningsIfNeeded = useCallback(
    async (data) => {
      if (data.airdropPointsReward === 0) return;

      airdropEffectRef.current?.play();
      playSound(airDropSound);
      const currentAirdropBalance =
        store.getState().dice.displayedAirDropBalance;
      updateAirdropCounter(data.airdropPointsReward + currentAirdropBalance);
      return new Promise((resolve) => setTimeout(resolve, 3000));
    },
    [updateAirdropCounter, playSound, airDropSound]
  );

  const roll = useRoll({
    diceRef,
    showWinningsIfNeeded,
    showAirdropWinningsIfNeeded,
    playDiceRollSound: () => playSound(diceRollSound),
  });

  useEffect(() => {
    dispatch(setLocked(false));
    dispatch(setRolling(false));
    dispatch(setDisplayedAirDropBalance(airdropBalance));
    dispatch(setDisplayedChipBalance(chipBalance));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      scoreCounterSound.stop();
      diceRollSound.stop();
      airDropSound.stop();
    };
  }, [scoreCounterSound, diceRollSound, airDropSound]);

  useEffect(() => {
    scoreCounterSound.mute(!isSoundEnabled);
    diceRollSound.mute(!isSoundEnabled);
    airDropSound.mute(!isSoundEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSoundEnabled]);

  return (
    <>
      <AirdropEffect ref={airdropEffectRef} />
      <div
        className="bg-no-repeat bg-cover bg-center mt-[-4rem] py-3 flex flex-col justify-end items-center overflow-hidden"
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          cursor: "pointer",
          backgroundImage: `url(${diceBackground})`,
        }}
      >
        <div className="absolute top-0 flex flex-row justify-between w-full px-2 mt-2">
          <SoundButton />
          <div className="flex flex-row gap-[-0.5rem]">
            <BalanceIndicator
              idToPass="chip-counter"
              className="w-10"
              coinIcon={chipIcon}
            />
            <BalanceIndicator
              idToPass="airdrop-counter"
              className="w-8"
              coinIcon={airdropCoinIcon}
            />
          </div>
        </div>
        <DiceSphere ref={diceRef} />
        <div className="w-full flex flex-col items-center justify-end mb-6">
          <BetSizeInput />
          <div className="w-full flex flex-row justify-between items-center px-2 mb-[-1rem]">
            <div className="posterable text-xl aspect-[468/211] h-[12.5vw] flex items-center justify-center text-center">
              x1.5
            </div>
            <div className="posterable text-xl aspect-[468/211] h-[12.5vw] flex items-center justify-center text-center">
              x1.5
            </div>
          </div>

          <div className="w-full h-[12.5vw] flex flex-row justify-between px-2 items-center">
            <div className="h-full mb-[0.7rem] mx-[-0.5rem]">
              <HalfOutcomeSelectionField isLow={true} />
            </div>
            <DoubleButton />
            <div className="h-full mb-[0.7rem] mx-[-0.5rem]">
              <HalfOutcomeSelectionField isLow={false} />
            </div>
          </div>
          <ParticularOutcomeSelectionField />
          <RollButton onClick={roll} />
        </div>
        <div className="absolute bottom-7 left-[0.1rem] opacity-40">
          <SquareIconButton
            icon={leftArrowIcon}
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </>
  );
};

export default DiceScreen;
