import { useRequestNewDiceGameMutation } from "../services/official";
import { useDispatch, useSelector } from "react-redux";
import { setRolling, setDisplayedAirDropBalance, setDisplayedChipBalance } from "../features/diceSlice";
import store from "../app/store";

function useRoll({ diceRef, showWinningsIfNeeded, showAirdropWinningsIfNeeded, playDiceRollSound }) {
    const { betSize, selectedDiceOutcome, isRolling } = useSelector((state) => state.dice);
    const [requestDiceGame] = useRequestNewDiceGameMutation();
    const dispatch = useDispatch();

    const numberToOutcomeName = {
        1: "One",
        2: "Two",
        3: "Three",
        4: "Four",
        5: "Five",
        6: "Six",
        "low": "Low",
        "high": "High",
    }

    return async () => {
        if (!diceRef.current || isRolling) return;

        dispatch(setRolling(true));

        var result = await requestDiceGame({
            betSizeInChips: betSize,
            expectedOutcome: numberToOutcomeName[selectedDiceOutcome],
        });
        if (result.error?.status === 400) {
            dispatch(setRolling(false));
            return;
        }
        let currentChipBalance = store.getState().dice.displayedChipBalance;
        let currentAirDropBalance = store.getState().dice.displayedAirDropBalance;

        dispatch(setDisplayedChipBalance(currentChipBalance - betSize));
        playDiceRollSound();
        await diceRef.current?.roll(result.data);

        await showAirdropWinningsIfNeeded(result.data);
        await showWinningsIfNeeded(result.data);
        setTimeout(() => dispatch(setRolling(false)), 500);
        currentChipBalance = store.getState().dice.displayedChipBalance;
        currentAirDropBalance = store.getState().dice.displayedAirDropBalance;

        dispatch(setDisplayedChipBalance(result.data.winningAmountInChips + currentChipBalance));
        dispatch(setDisplayedAirDropBalance(result.data.airdropPointsReward + currentAirDropBalance));
    };
};

export { useRoll };