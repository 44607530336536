import SquareIconButton from "./SquareIconButton";
import soundEnabledIcon from "../../assets/images/slot_machine/sound_enabled_icon.png";
import soundDisabledIcon from "../../assets/images/slot_machine/sound_disabled_icon.png";
import { useSelector, useDispatch } from "react-redux";
import { setSoundEnabled } from "../../features/diceSlice";
import { useCallback } from "react";

const SoundButton = () => {
  const dispatch = useDispatch();
  const { isSoundEnabled } = useSelector((state) => state.dice);

  const toggleSound = useCallback(() => {
    dispatch(setSoundEnabled(!isSoundEnabled));
  }, [dispatch, isSoundEnabled]);

  return (
    <SquareIconButton
      icon={isSoundEnabled ? soundEnabledIcon : soundDisabledIcon}
      onClick={toggleSound}
    />
  );
};

export default SoundButton;
