import { setSelectedDiceOutcome } from "../../features/diceSlice";
import { useSelector, useDispatch } from "react-redux";
import yellowTechFieldSelected from "../../assets/images/dice/yellow_tech_field_selected.png";
import yellowTechField from "../../assets/images/dice/yellow_tech_field.png";
import dice1Outcome from "../../assets/images/dice/dice_1_outcome.png";
import dice2Outcome from "../../assets/images/dice/dice_2_outcome.png";
import dice3Outcome from "../../assets/images/dice/dice_3_outcome.png";
import dice4Outcome from "../../assets/images/dice/dice_4_outcome.png";
import dice5Outcome from "../../assets/images/dice/dice_5_outcome.png";
import dice6Outcome from "../../assets/images/dice/dice_6_outcome.png";
import { useMemo } from "react";

const HalfOutcomeSelectionField = ({ isLow }) => {
  const dispatch = useDispatch();
  const selectedDiceOutcome = useSelector(
    (state) => state.dice.selectedDiceOutcome
  );

  const { isRolling } = useSelector((state) => state.dice);

  const outcomes = useMemo(
    () => [
      dice1Outcome,
      dice2Outcome,
      dice3Outcome,
      dice4Outcome,
      dice5Outcome,
      dice6Outcome,
    ],
    []
  );

  const outcomeNumbers = useMemo(
    () => (isLow ? [1, 2, 3] : [4, 5, 6]),
    [isLow]
  );

  const isSelected = useMemo(
    () => selectedDiceOutcome === (isLow ? "low" : "high"),
    [selectedDiceOutcome, isLow]
  );

  const disabledFilter = useMemo(
    () => (isRolling ? "grayscale(1)" : ""),
    [isRolling]
  );

  return (
    <div className="relative h-[120%] aspect-[552/245] flex flex-col items-center">
      <img src={isSelected ? yellowTechFieldSelected : yellowTechField} />
      <button
        className="absolute w-full aspect-[552/245] flex flex-row items-center justify-between px-[1.3rem] pb-0.5 bottom-0"
        style={{
          filter: `${disabledFilter}`,
        }}
        onClick={() => dispatch(setSelectedDiceOutcome(isLow ? "low" : "high"))}
        disabled={isRolling}
      >
        {" "}
        {outcomeNumbers.map((i) => (
          <>
            <img
              style={{
                opacity: isSelected ? 1 : 0.5,
              }}
              src={outcomes[i - 1]}
              className="aspect-square bg-no-repeat bg-cover w-auto h-[45%]"
            ></img>
          </>
        ))}
      </button>
    </div>
  );
};

export default HalfOutcomeSelectionField;
