import { useState } from "react";
import { openLink } from "../utils/socialMedia";

const ChangellyWidgetButton = ({ currency, symbol, address }) => {
  const currencyToChangellySymbol = {
    TON: "ton",
    Dollars: "usdton",
    BTC: "btc",
    ETH: "eth",
    Tron: "trx",
    "USDT (ERC-20)": "usd20",
    "USDT (TRC-20)": "usdtrx",
  };
  return (
    <button
      className="w-full flex items-center justify-center rounded-lg p-3 mt-4"
      style={{
        background:
          "linear-gradient(to top, rgba(21, 207, 5, 0.5), rgba(145, 249, 52, 0.5))",
      }}
      onClick={() =>
        openLink(
          `https://widget.changelly.com/?from=eur,usd&to=${currencyToChangellySymbol[currency]}&amount=100&address=${address}&fromDefault=usd&toDefault=${currencyToChangellySymbol[currency]}&type=no-rev-share&headerId=1&logo=hide`
        )
      } // Show the widget
    >
      <span className="gradient-text-green underline font-semibold text-center w-full">
        Buy {currency} with Credit Card
      </span>
    </button>
  );
};

export default ChangellyWidgetButton;
