import betSizeField from "../../assets/images/dice/bet_size_field.png";
import betSizeFieldOverlay from "../../assets/images/dice/bet_size_field_overlay.png";
import { useSelector, useDispatch } from "react-redux";
import { useMemo, useCallback, useEffect } from "react";
import { setBetSize } from "../../features/diceSlice";
import { useUserBalance } from "../../utils/user";
import minusButtonImage from "../../assets/images/dice/minus_button.png";
import plusButtonImage from "../../assets/images/dice/plus_button.png";

const BetSizeInput = () => {
  const dispatch = useDispatch();
  const { betSize, isRolling } = useSelector((state) => state.dice);
  const { chipBalance: balance } = useUserBalance();

  const incrementAmount = useMemo(() => {
    const result = Math.floor(balance * 0.01);

    return Math.max(1, result);
  }, [balance]);

  const incrementBetSize = useCallback(() => {
    const newBetSize = Math.min(betSize + incrementAmount, balance);
    dispatch(setBetSize(newBetSize));
  }, [dispatch, betSize, balance, incrementAmount]);

  const decrementBetSize = useCallback(() => {
    const newBetSize = Math.max(
      betSize - incrementAmount,
      Math.floor(betSize * 0.95),
      10
    );
    dispatch(setBetSize(newBetSize));
  }, [dispatch, betSize, incrementAmount]);

  const isDecrementButtonDisabled = useMemo(() => {
    return betSize <= 10 || isRolling;
  }, [betSize, isRolling]);

  const isIncrementButtonDisabled = useMemo(() => {
    return betSize >= balance || isRolling;
  }, [betSize, balance, isRolling]);

  useEffect(() => {
    if (isRolling) return;

    if (betSize > balance) {
      dispatch(setBetSize(balance));
    }
    if (betSize === null || betSize === undefined) {
      const newBetSize = Math.max(Math.min(balance, incrementAmount), 10);
      dispatch(setBetSize(newBetSize));
    }
  }, [balance, betSize, dispatch, isRolling, incrementAmount]);

  return (
    <div className="w-full flex flex-col items-start">
      <div className="relative w-full aspect-[854/347] flex flex-col items-center mb-[-2.4rem] mt-[-0.5rem]">
        <div className="absolute posterable bottom-10 w-[55%] aspect-[854/221] flex flex-col items-start pl-4 text-lg bet-input-title">
          BET{" "}
        </div>
        <img
          src={betSizeFieldOverlay}
          className="absolute w-[55%] aspect-[854/347] h-auto bottom-0"
          style={{
            mixBlendMode: "overlay",
          }}
        />
        <div
          className="absolute w-[55%] aspect-[854/221] h-auto bottom-5 bg-cover bg-no-repeat bg-center flex flex-row justify-between items-center px-2"
          style={{
            backgroundImage: `url(${betSizeField})`,
          }}
        >
          <button
            onClick={decrementBetSize}
            className="w-8 z-[2] top-3.5 left-3"
            disabled={isDecrementButtonDisabled}
            style={{
              filter: isDecrementButtonDisabled ? "grayscale(1)" : "none",
            }}
          >
            <img src={minusButtonImage} />
          </button>
          <div className="text-2xl alien-encounters pt-1">{betSize}</div>
          <button
            onClick={incrementBetSize}
            className="w-8 z-[2] top-3.5 left-3"
            disabled={isIncrementButtonDisabled}
            style={{
              filter: isIncrementButtonDisabled ? "grayscale(1)" : "none",
            }}
          >
            <img src={plusButtonImage} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BetSizeInput;
