import * as minorWinEffectAnimation from "../assets/animations/minor_win_effect.json";
import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import Lottie from "react-lottie";

const MinorWinEffect = forwardRef(({}, ref) => {
  const [isMinorWinEffectPlaying, setMinorWinEffectPlaying] = useState(false);
  const playPromiseResolveRef = useRef(null); // To store the resolve function for the promise

  useImperativeHandle(ref, () => ({
    play: () => {
      if (isMinorWinEffectPlaying) return;
      setMinorWinEffectPlaying(true);

      return new Promise((resolve) => {
        playPromiseResolveRef.current = resolve;
      });
    },
    isPlaying: () => {
      return isMinorWinEffectPlaying;
    },
  }));

  const minorWinEffectAnimationOptions = {
    loop: false,
    autoplay: false,
    animationData: minorWinEffectAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className="absolute z-[9999] w-full h-full"
      style={{
        display: isMinorWinEffectPlaying ? "block" : "none",
      }}
    >
      <Lottie
        isClickToPauseDisabled={true}
        options={minorWinEffectAnimationOptions}
        isStopped={!isMinorWinEffectPlaying}
        isPaused={!isMinorWinEffectPlaying}
        eventListeners={[
          {
            eventName: "complete",
            callback: () => {
              setMinorWinEffectPlaying(false);
              if (playPromiseResolveRef.current) {
                playPromiseResolveRef.current();
                playPromiseResolveRef.current = null;
              }
            },
          },
        ]}
      />
    </div>
  );
});

export default MinorWinEffect;
