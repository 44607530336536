import { setSelectedDiceOutcome } from "../../features/diceSlice";
import { useSelector, useDispatch } from "react-redux";
import blueTechField from "../../assets/images/dice/blue_tech_field.png";
import blueTechFieldOverlay from "../../assets/images/dice/blue_tech_field_overlay.png";
import dice1Outcome from "../../assets/images/dice/dice_1_outcome.png";
import dice2Outcome from "../../assets/images/dice/dice_2_outcome.png";
import dice3Outcome from "../../assets/images/dice/dice_3_outcome.png";
import dice4Outcome from "../../assets/images/dice/dice_4_outcome.png";
import dice5Outcome from "../../assets/images/dice/dice_5_outcome.png";
import dice6Outcome from "../../assets/images/dice/dice_6_outcome.png";
import diceSeparator from "../../assets/images/dice/dice_separator.svg";
import { useMemo } from "react";

const ParticularOutcomeSelectionField = ({}) => {
  const dispatch = useDispatch();
  const { selectedDiceOutcome, isRolling } = useSelector((state) => state.dice);

  const outcomes = useMemo(
    () => [
      dice1Outcome,
      dice2Outcome,
      dice3Outcome,
      dice4Outcome,
      dice5Outcome,
      dice6Outcome,
    ],
    []
  );

  return (
    <>
      <div className="text-xl posterable mb-[-1.9rem] mt-1">x5</div>
      <div className="relative w-full aspect-[780/262] flex flex-col items-center mb-[-1.5rem] mt-[-0.5rem]">
        <img
          src={blueTechFieldOverlay}
          className="absolute w-[80%] aspect-[780/262] h-auto bottom-1"
          style={{
            mixBlendMode: "overlay",
          }}
        />
        <img
          src={blueTechField}
          className="absolute w-[80%] aspect-[780/162] h-auto bottom-8"
        />
        <div className="absolute w-[80%] aspect-[780/162] flex flex-row items-center justify-between px-4 bottom-8">
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <>
              <button
                style={{
                  backgroundImage: `url(${outcomes[i - 1]})`,
                  opacity: selectedDiceOutcome === i ? 1 : 0.5,
                  filter: isRolling ? "grayscale(1)" : "",
                }}
                disabled={isRolling}
                className="aspect-square bg-no-repeat bg-cover w-auto h-[55%]"
                onClick={() => dispatch(setSelectedDiceOutcome(i))}
              ></button>
              {i !== 6 && (
                <img
                  src={diceSeparator}
                  className="h-[65%] w-auto"
                  style={{ mixBlendMode: "overlay" }}
                />
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default ParticularOutcomeSelectionField;
