import React, {
  useState,
  useMemo,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";
import diceStartFrom1 from "../../assets/animations/dice/1_start.json";
import diceStartFrom2 from "../../assets/animations/dice/2_start.json";
import diceStartFrom3 from "../../assets/animations/dice/3_start.json";
import diceStartFrom4 from "../../assets/animations/dice/4_start.json";
import diceStartFrom5 from "../../assets/animations/dice/5_start.json";
import diceStartFrom6 from "../../assets/animations/dice/6_start.json";
import diceEndAt1 from "../../assets/animations/dice/1_end.json";
import diceEndAt2 from "../../assets/animations/dice/2_end.json";
import diceEndAt3 from "../../assets/animations/dice/3_end.json";
import diceEndAt4 from "../../assets/animations/dice/4_end.json";
import diceEndAt5 from "../../assets/animations/dice/5_end.json";
import diceEndAt6 from "../../assets/animations/dice/6_end.json";
import sphere from "../../assets/animations/dice/sphere_start.json";
import smallCracksSphere from "../../assets/animations/dice/small_cracks_sphere.json";
import bigCrackSphere from "../../assets/animations/dice/big_crack_sphere.json";
import crashedSphere from "../../assets/animations/dice/crashed_sphere.json";
import sphereStand from "../../assets/images/dice/stand.png";

import { setConsecutiveWins } from "../../features/diceSlice";
import MinorWinEffect from "../MinorWinEffect";

const DiceSphere = forwardRef(({}, ref) => {
  const dispatch = useDispatch();
  const [currentDiceState, setCurrentDiceState] = useState(1);
  const [pendingFinalState, setPendingFinalState] = useState(currentDiceState);

  const minorWinEffectRef = useRef();
  const [showStartAnimation, setShowStartAnimation] = useState(true);
  const [showEndAnimation, setShowEndAnimation] = useState(false);
  const { selectedDiceOutcome, consecutiveWins, isRolling } = useSelector(
    (state) => state.dice
  );

  const isTheBetWon = useMemo(() => {
    return (
      selectedDiceOutcome === pendingFinalState ||
      (selectedDiceOutcome === "low" && pendingFinalState < 4) ||
      (selectedDiceOutcome === "high" && pendingFinalState > 3)
    );
  }, [selectedDiceOutcome, pendingFinalState]);

  const diceStartAnimations = useMemo(
    () => ({
      1: diceStartFrom1,
      2: diceStartFrom2,
      3: diceStartFrom3,
      4: diceStartFrom4,
      5: diceStartFrom5,
      6: diceStartFrom6,
    }),
    []
  );

  const diceEndAnimations = useMemo(
    () => ({
      1: diceEndAt1,
      2: diceEndAt2,
      3: diceEndAt3,
      4: diceEndAt4,
      5: diceEndAt5,
      6: diceEndAt6,
    }),
    []
  );

  const [currentStartAnimation, setCurrentStartAnimation] = useState(
    diceStartAnimations[currentDiceState]
  );
  const [currentEndAnimation, setCurrentEndAnimation] = useState(
    diceEndAnimations[2]
  );

  const [shouldPulsate, setShouldPulsate] = useState(false);

  const setSphereAccordingToConsecutiveWins = useCallback((consecutiveWins) => {
    switch (consecutiveWins) {
      case 1:
        setSphereData(smallCracksSphere);
        break;
      case 2:
        setSphereData(bigCrackSphere);
        break;
      case 3:
        setSphereData(crashedSphere);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    setSphereAccordingToConsecutiveWins(consecutiveWins);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      roll: ({ actualOutcome }) => {
        const newFinalState = actualOutcome;
        setPendingFinalState(newFinalState);

        // Update the animations based on new states
        setCurrentStartAnimation(diceStartAnimations[currentDiceState]);
        setCurrentEndAnimation(diceEndAnimations[newFinalState]);
        setShowStartAnimation(true);
        setShowEndAnimation(false);
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 4000);
        });
      },
      highlightWinning: async () => {
        minorWinEffectRef.current.play();
        setShouldPulsate(true);
        return new Promise((resolve) => {
          setTimeout(() => {
            setShouldPulsate(false);
            resolve();
          }, 2000);
        });
      },
    }),
    [isRolling, currentDiceState, diceStartAnimations, diceEndAnimations]
  );

  const handleStartAnimationComplete = () => {
    setShowStartAnimation(false);
    setShowEndAnimation(true);
  };

  const handleEndAnimationComplete = async () => {
    if (isTheBetWon) {
      setSphereAccordingToConsecutiveWins(consecutiveWins + 1);
      dispatch(setConsecutiveWins(consecutiveWins + 1));
    } else if (consecutiveWins > 0) {
      const sphereContainer = document.getElementById("dice-sphere-container");
      if (consecutiveWins < 3) {
        sphereContainer.style.filter = "";
        await new Promise((resolve) => setTimeout(resolve, 500));
        sphereContainer.style.filter = "brightness(1.3) contrast(1.8)";
      }

      setSphereData(sphere);
      dispatch(setConsecutiveWins(0));
    }
    setCurrentDiceState(pendingFinalState);
  };

  const [sphereData, setSphereData] = useState(sphere);

  const sphereOptions = {
    loop: false,
    autoplay: true,
    animationData: sphereData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const startOptions = {
    loop: false,
    autoplay: false,
    animationData: currentStartAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const endOptions = {
    loop: false,
    autoplay: true,
    animationData: currentEndAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        position: "relative",
        width: "90vw",
        height: "28%",
        cursor: "pointer",
        backgroundColor: "transparent",
        pointerEvents: "none",
      }}
    >
      <MinorWinEffect ref={minorWinEffectRef} />
      <div
        id="dice-sphere-container"
        style={{
          position: "absolute", // Correct "absolute relative" to just "absolute"
          width: "100%", // Full width of the parent
          left: "50%", // Center horizontally
          transform: "translateX(-50%) scale(1.5)", // Center and scale
          zIndex: 2,
          display: "flex", // Use flexbox for alignment
          flexDirection: "column", // Stack the Lottie and stand vertically
          alignItems: "center", // Center both elements horizontally
          marginTop: "-2rem",
          filter: consecutiveWins < 3 ? "brightness(1.3) contrast(1.8)" : "",
          transition: "filter 0.5s",
        }}
      >
        {/* Lottie Sphere */}
        <Lottie
          options={sphereOptions}
          isStopped={false}
          isPaused={false}
          isClickToPauseDisabled={true}
          style={{ width: "100%", height: "auto", zIndex: 2 }}
        />

        {/* Sphere Stand */}
        <img
          src={sphereStand}
          style={{
            width: "63%", // Adjust width as needed
            height: "auto",
            marginTop: "-78vw", // If necessary, fine-tune positioning
          }}
          alt="Sphere Stand"
        />
        <>
          {showStartAnimation && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Lottie
                options={startOptions}
                isStopped={false}
                isPaused={!isRolling}
                isClickToPauseDisabled={true}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: handleStartAnimationComplete,
                  },
                ]}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          )}

          {showEndAnimation && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Lottie
                options={endOptions}
                isStopped={false}
                isPaused={false}
                isClickToPauseDisabled={true}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: handleEndAnimationComplete,
                  },
                ]}
                style={{
                  width: "100%",
                  height: "auto",
                  animation: shouldPulsate
                    ? "pulsate 0.5s infinite ease-in-out"
                    : "",
                }}
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
});

export default DiceSphere;
