import { useTonAddress, useTonConnectModal } from "@tonconnect/ui-react";
import { useSelector } from "react-redux";
import useSendDeposit from "../../utils/tonTransactions";
import { useMemo, useState } from "react";
import InputField from "../../components/InputField";
import MinimumDepositInfo from "../../components/MinimumDepositInfo";
import AmountSelectionButtons from "../../components/AmountSelectionButtons";
import TonWeb from "tonweb";
import telegramWalletIcon from "../../assets/images/telegram_wallet_icon.svg";
import Button from "../../components/Button";
import ConfirmWalletDisconnectPopUp from "./ConfirmWalletDisconnectPopUp";
import { useGetDepositWalletQuery } from "../../services/official";

const ConnectedWalletDepositSection = ({
  minimumDeposits,
  currency,
  symbol,
}) => {
  const [amount, setAmount] = useState("");
  const { open } = useTonConnectModal();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [areSelectionButtonsInUse, setSelectionButtonsInUse] = useState(false);
  const connectedTonAddress = useTonAddress();
  const sendDeposit = useSendDeposit();
  const { id } = useSelector((state) => state.user);
  const { data } = useGetDepositWalletQuery(
    { userId: id },
    { keepPreviousData: true }
  );

  const isConnected = useMemo(
    () => connectedTonAddress !== "",
    [connectedTonAddress]
  );

  const errorMessage = useMemo(() => {
    if (amount === "") return null;

    if (amount < minimumDeposits[currency]) {
      return `Minimum deposit is ${minimumDeposits[currency]} ${symbol}.`;
    }

    const regex = {
      TON: "^[0-9]+(.[0-9]{1,9})?$",
      Dollars: "^[0-9]+(.[0-9]{1,6})?$",
    };

    if (!amount.match(regex[currency])) {
      return `Invalid ${symbol} amount.`;
    }

    return null;
  }, [amount, currency, minimumDeposits, symbol]);

  return (
    <>
      <ConfirmWalletDisconnectPopUp
        isOpen={isConfirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
      />
      <div className="w-full flex flex-col items-center justify-center gap-4 min-h-full">
        {isConnected ? (
          <>
            <div className="w-full mt-1">
              <Button
                icon={telegramWalletIcon}
                onClick={() => setConfirmModalOpen(true)}
                variant="fifth"
                text={
                  "Connected wallet: " +
                  connectedTonAddress.slice(0, 4) +
                  "..." +
                  connectedTonAddress.slice(-4)
                }
              />
            </div>
            <div className="mt-2 flex flex-col gap-4 w-full">
              <div className="w-full gap-2 flex flex-col">
                <InputField
                  pattern="^[0-9]+(\.[0-9]{1,9})?$"
                  inputMode="decimal"
                  label="Enter the amount you want to deposit"
                  placeholder={`Amount in ${symbol}`} 
                  onChange={(e) => {
                    setSelectionButtonsInUse(false);
                    setAmount(e.target.value.replace(",", "."));
                  }}
                  value={amount}
                  trailingText={
                    amount !== "" && currency === "TON"
                      ? `≈ $${(amount * data?.tonPriceInUsd).toFixed(2)}`
                      : null
                  }
                  trailingTextSize="text-sm"
                />
                <AmountSelectionButtons
                  isSelectionInUse={areSelectionButtonsInUse}
                  setSelectionInUse={setSelectionButtonsInUse}
                  tonPriceInUsd={data?.tonPriceInUsd}
                  setAmount={setAmount}
                  amount={amount}
                  currency={currency}
                />
                <MinimumDepositInfo
                  minimumAmount={minimumDeposits[currency]}
                  currencySymbol={symbol}
                />
              </div>

              <div>
                <div className="mb-2 min-h-6 text-sm text-red-500 opacity-85 text-center">
                  {errorMessage ?? ""}
                </div>
                <Button
                  isDisabled={errorMessage !== null}
                  onClick={() =>
                    sendDeposit(
                      currency === "TON"
                        ? TonWeb.utils.toNano(amount)
                        : amount * 1e6,
                      data?.tonAddress,
                      currency
                    )
                  }
                  variant="primary"
                  text="Deposit"
                />
              </div>
            </div>
          </>
        ) : (
          <div className="mt-3 w-full">
            <Button
              icon={telegramWalletIcon}
              onClick={open}
              variant="fourth"
              text="Connect Wallet"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ConnectedWalletDepositSection;
