import squareIconButtonBackground from "../../assets/images/dice/square_icon_button_background.png";

const SquareIconButton = ({ icon, onClick }) => (
  <button
    style={{
      backgroundImage: `url(${squareIconButtonBackground})`,
    }}
    className="w-12 h-12 bg-no-repeat bg-center bg-cover flex items-center justify-center"
    onClick={onClick}
  >
    <img
      src={icon}
      alt="icon"
      className="w-5 aspect-square"
      style={{
        mixBlendMode: "overlay",
      }}
    />
  </button>
);

export default SquareIconButton;
