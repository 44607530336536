import CopyAddressField from "../../components/CopyAddressField";
import DropdownMenu from "../../components/DropdownMenu";
import QrCode from "../../components/QrCode";
import { useMemo, useState } from "react";
import { useGetDepositWalletQuery } from "../../services/official";
import { useSelector } from "react-redux";
import tonLogo from "../../assets/images/ton_logo.svg";
import bitcoinLogo from "../../assets/images/bitcoin_logo.png";
import ethereumLogo from "../../assets/images/ethereum_logo.png";
import tetherLogo from "../../assets/images/tether_logo.png";
import trcTetherLogo from "../../assets/images/trc_tether_logo.png";
import tronLogo from "../../assets/images/tron_logo.png";
import tonTetherLogo from "../../assets/images/ton_tether_logo.svg";
import depositBonusBanner from "../../assets/images/deposit_bonus_banner.png";
import AnnouncementCard from "../../components/AnnouncementCard";
import { dollarWorthInChips } from "../../constants";
import ConnectedWalletDepositSection from "./ConnectedWalletDepositSection";
import MinimumDepositInfo from "../../components/MinimumDepositInfo";
import ChangellyWidgetButton from "../../components/ChangellyWidgetButton";
import { useTonAddress } from "@tonconnect/ui-react";

const DepositTabContent = () => {
  const currencies = [
    "Dollars",
    "TON",
    "Tron",
    "ETH",
    "USDT (ERC-20)",
    "USDT (TRC-20)",
    "BTC",
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { id } = useSelector((state) => state.user);
  const { data } = useGetDepositWalletQuery(
    { userId: id },
    { keepPreviousData: true }
  );

  const minimumDeposits = {
    Dollars: "1",
    TON: "0.25",
    BTC: "0.000074",
    ETH: "0.0019",
    Tron: "6",
    "USDT (ERC-20)": "5",
    "USDT (TRC-20)": "5",
  };

  const currencyToSymbol = {
    Dollars: "USDT",
    TON: "TON",
    BTC: "BTC",
    ETH: "ETH",
    Tron: "TRX",
    "USDT (ERC-20)": "USDT",
    "USDT (TRC-20)": "USDT",
  };

  const feeTexts = [
    "2-5 min",
    "2-5 min",
    "2-10 min",
    "3-15 min",
    "3-15 min",
    "2-10 min",
    "20-60 min",
  ];

  const addresses = useMemo(
    () => [
      data?.tronAddress,
      data?.ethereumAddress,
      data?.ethereumAddress,
      data?.tronAddress,
      data?.bitcoinAddress,
    ],
    [data]
  );
  const icons = [
    <img src={tonTetherLogo} className="h-7 w-auto" alt="TON Logo" />,
    <img src={tonLogo} className="h-7 w-auto" alt="TON Logo" />,
    <img src={tronLogo} className="h-7 w-auto" alt="Tron Logo" />,
    <img src={ethereumLogo} className="h-7 w-auto" alt="Ethereum Logo" />,
    <img src={tetherLogo} className="h-7 w-auto" alt="USDT (ERC-20) Logo" />,
    <img src={trcTetherLogo} className="h-7 w-auto" alt="USDT (TRC-20) Logo" />,
    <img src={bitcoinLogo} className="h-7 w-auto" alt="Bitcoin Logo" />,
  ];
  const connectedTonAddress = useTonAddress();
  const address = useMemo(
    () =>
      selectedIndex > 1 ? addresses[selectedIndex - 2] : connectedTonAddress,
    [addresses, selectedIndex, connectedTonAddress]
  );
  const promotionEndDate = useMemo(
    () => Date.parse("2024-12-10T21:59:59Z"),
    []
  );

  return (
    <>
      <div className="flex flex-col gap-3">
        {promotionEndDate > Date.now() && (
          <div>
            <AnnouncementCard
              image={depositBonusBanner}
              date={promotionEndDate}
            />
            <div className="opacity-30 text-xs text-end mt-0.5">
              Deposits of $20 or more qualify for the promotion.
            </div>
          </div>
        )}
        <div className="z-[2] flex flex-col items-center w-full">
          <DropdownMenu
            recommendedIndex={0}
            icons={icons}
            label={
              <div className="flex justify-between text-xs mb-[0.5rem]">
                <span className="opacity-35">Currency</span>{" "}
                <span className="gradient-text-red font-semibold">
                  Exchange rate $1 = {dollarWorthInChips} credits
                </span>
              </div>
            }
            items={currencies}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            isCentered={false}
            feeTexts={feeTexts}
          />
          {selectedIndex !== 0  && (
              <ChangellyWidgetButton
                currency={currencies[selectedIndex]}
                symbol={currencyToSymbol[currencies[selectedIndex]]}
                address={address}
              />
            )}
        </div>
        <div
          className="flex flex-col items-center gap-5 w-full"
          id="deposit-data"
        >
          {selectedIndex <= 1 ? (
            <ConnectedWalletDepositSection
              minimumDeposits={minimumDeposits}
              currency={currencies[selectedIndex]}
              symbol={currencyToSymbol[currencies[selectedIndex]]}
            />
          ) : (
            <>
              <div className="w-full justify-end mt-[-0.4rem] mb-[-0.6rem]"></div>

              <div className="w-full">
                <CopyAddressField label={"Deposit Address"} address={address} />
                <div className="flex flex-row justify-end items-center mt-1.5">
                  <MinimumDepositInfo
                    currency={currencies[selectedIndex]}
                    minimumAmount={minimumDeposits[currencies[selectedIndex]]}
                    currencySymbol={currencyToSymbol[currencies[selectedIndex]]}
                  />
                </div>
              </div>

              <QrCode value={address} />
              <div className="text-xs mt-[0.5rem] opacity-85 text-center text-red-500 font-bold">
                Transfers made below the minimum amount or to an invalid address
                will be lost.
              </div>
            </>
          )}
        </div>
        <div className="self-center justify-center mt-10"></div>
      </div>
    </>
  );
};

export default DepositTabContent;
